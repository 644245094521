import { takeEvery } from 'redux-saga/effects';

import { Types as AccountTypes } from 'actions/account';
import { fetchSaga } from 'api/fetchSaga';

import { config } from 'config';

import { httpMethods } from 'enums/httpMethods';

import { clearStore } from './app';

const { apis } = config;

const { DELETE_USER } = AccountTypes;

export function* deleteUser() {
  const params = {
    path: apis.account,
    method: httpMethods.DELETE
  };

  const response = yield fetchSaga({
    params,
    actionType: DELETE_USER
  });

  if (response) {
    yield clearStore();
  }
}

export function* deleteUserSaga() {
  yield takeEvery(DELETE_USER, deleteUser);
}

import { createDraftSafeSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getBankSelectionList } from 'selectors/oldBankSelector';
import { Bank } from 'models/Bank';
import { RootState } from 'store/store';

export interface BanksState {
  banks: Bank[];
  selectedBank: Bank | null;
}

const INITIAL_STATE: BanksState = {
  banks: [],
  selectedBank: null
};

export const banksSlice = createSlice({
  name: 'banks',
  initialState: INITIAL_STATE,
  reducers: {
    selectBank(state, action: PayloadAction<{ selectedBank: Bank }>) {
      state.selectedBank = action.payload.selectedBank;
    },
    clearBankSelection(state) {
      state.selectedBank = null;
    }
  }
});

export const { selectBank, clearBankSelection } = banksSlice.actions;

export const getSelectedBank = createDraftSafeSelector(
  (state: RootState) => state,
  getBankSelectionList,
  ({ banks: { selectedBank } }, bankSelectionList): null | Bank => {
    if (!selectedBank) return null;

    const bank = bankSelectionList.find(bank => bank.id === selectedBank.id);

    return {
      ...selectedBank,
      ...(bank?.bigLogoUrl ? { bigLogoUrl: bank.bigLogoUrl } : undefined)
    };
  }
);

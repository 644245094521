import MuiButton, { ButtonProps } from '@mui/material/Button';
import withStyles from '@mui/styles/withStyles';
import styled from 'styled-components';

import { themeBreakPoints } from 'enums/keys';

const ButtonWithAutoWidth = withStyles(theme => ({
  fullWidth: {
    [theme.breakpoints.up('sm')]: {
      width: 'auto'
    }
  }
}))(MuiButton);

export const Button = styled(MuiButton)``;

interface PrimaryButtonProps extends ButtonProps {
  uppercase?: boolean;
  dataId?: string;
  isDestructive?: boolean;
}

export const PrimaryButton = styled(
  ({ uppercase: _uppercase, isDestructive: _isDestructive, ...props }: PrimaryButtonProps) => (
    <ButtonWithAutoWidth {...props} variant="contained" color="primary" />
  )
)`
  && {
    text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'unset')};
    &:hover {
      background-color: ${({ isDestructive, theme: { palette } }) =>
        isDestructive ? palette.text.red ?? palette.red : palette.primary.main};
    }
    svg {
      color: ${({ theme: { palette } }) => palette.primary.contrastText};
    }
    ${({ isDestructive, theme: { palette } }) =>
      isDestructive ? `background-color: ${palette.text.red ?? palette.red}` : ''}
  }
`;

export const SecondaryButton = styled(ButtonWithAutoWidth).attrs({ variant: 'outlined' })`
  && {
    text-transform: none;
    color: ${({ theme: { palette } }) => palette.primary.main};
    svg {
      color: ${({ theme: { palette } }) => palette.primary.main};
    }
  }
`;

export const GroupPrimaryButton = styled(PrimaryButton)`
  && {
    margin: 0 5px;
  }
`;

export const GroupSecondaryButton = styled(SecondaryButton)`
  && {
    margin: 0 5px;
    @media (max-width: ${({ theme: { breakpoints } }) =>
        `${breakpoints.values[themeBreakPoints.sm]}px`}) {
      margin: 0 0 10px 0;
    }
  }
`;

export const FullButton = styled(PrimaryButton)`
  && {
    width: 100%;
    text-align: center;
  }
`;

interface TextButtonProps extends ButtonProps {
  uppercase?: boolean;
  semiBold?: boolean;
  fullWidth?: boolean;
}

export const TextButton = styled(
  ({
    uppercase: _uppercase,
    semiBold: _semiBold,
    fullWidth: _fullWidth,
    ...props
  }: TextButtonProps) => <Button {...props} color="primary" />
)`
  && {
    text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'unset')};
    font-weight: ${({ theme, semiBold }) =>
      semiBold ? theme.typography.fontWeights.semiBold : ''};
    ${({ fullWidth }) => (fullWidth ? `width: 100%` : '')}
  }
`;

export const LinkButton = styled(TextButton)`
  && {
    padding: 0;
  }
`;

export const OnboardingButtonsWrapper = styled.div`
  ${TextButton} {
    margin-top: 20px;
  }
`;
